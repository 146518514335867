import styles from './login-page.module.css';

import { Fragment, useEffect, useState } from "react";
import DGLogo from "../../assets/images/dg-logo.png";
import CustomInputField from '../../components/custom-input-field/custom-input-field';
import CustomButton from '../../components/custom-button/custom-button';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/authService';
import useEmployeeStore from '../../stores/employeeStore';
import { useTranslation } from 'react-i18next';
import Quote from './components/quote';
import LanguageSelector from '../../components/language-selector/language-selector';
import usePageTitle from '../../hooks/usePageTitle';

const LoginPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set page title
    usePageTitle(t("pages.login.title"));

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');

    // Stores
    const { employee, setEmployee } = useEmployeeStore();

    useEffect(() => {
        if (employee) {
            navigate("/");
        }
    }, [employee, navigate]);

    const handleLogin = async () => {
        let valid = true;
        setEmailError('');
        setPasswordError('');

        // Basic email validation
        if (!email) {
            setEmailError(t("invalid_input.email_empty"));
            valid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError(t("invalid_input.email_invalid"));
            valid = false;
        }

        // Check if the password is not empty
        if (!password) {
            setPasswordError(t("invalid_input.password_empty"));
            valid = false;
        }

        if (valid) {
            try {
                setIsLoading(true);
                const employeeData = await login(email, password);
                setEmployee(employeeData);
            } catch (error) {
                // @ts-ignore
                setLoginError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className={styles.pageContainerFull}>
            <div className={styles.languageSelectContainer}>
                <LanguageSelector className={styles.languageSelect} />
            </div>

            <div className="row">
                <div className={`col-md-6 d-none d-md-flex flex-column ${styles.leftPanel}`}>
                    <Quote
                        className={`mt-4 ${styles.loginQuote}`}
                        text="The best way to predict the future is to create it!"
                        author="Peter Drucker"
                        boldWords={["predict the future", "create it!"]}
                    />
                </div>
                <div className={`col-md-6 d-flex flex-column align-items-center justify-content-center ${styles.rightPanel}`}>
                    <img src={DGLogo} width={200} className="mx-auto d-block d-sm-none" alt="DG Logo" />
                    <h4 className="text-center mt-4 mb-5 d-sm-none">{t("pages.login.subtitle")}</h4>
                    <h4 className="mb-5 d-none d-sm-block text-center">
                        {t("pages.login.subtitle").split('\n').map((line, index) => (
                            <Fragment key={index}>
                                {line}
                                <br />
                            </Fragment>
                        ))}
                    </h4>
                    <form onSubmit={handleLogin} className={styles.loginForm}>
                        <div className="mb-3">
                            <CustomInputField
                                id="email"
                                label={t("labels.email")}
                                type="email"
                                placeholder="max@mustermann.de"
                                value={email}
                                errorMessage={emailError}
                                onClear={() => setEmail("")}
                                onChange={(value: string) => {
                                    setEmail(value);
                                    setEmailError("");
                                }}
                                required
                            />
                        </div>
                        <div className={loginError ? "" : "mb-4"}>
                            <CustomInputField
                                id="password"
                                label={t("labels.password")}
                                type="password"
                                placeholder="********"
                                value={password}
                                errorMessage={passwordError}
                                onClear={() => setPassword("")}
                                onChange={(value: string) => {
                                    setPassword(value);
                                    setPasswordError("");
                                }}
                                required
                            />
                        </div>
                        {loginError && (
                            <p className="ms-2 mb-4 text-danger">{loginError}</p>
                        )}
                        <CustomButton
                            className="w-100"
                            title={t("buttons.login")}
                            isLoading={isLoading}
                            onClick={handleLogin}
                        />
                    </form>
                    <div className={`${styles.footerContainer} mt-auto w-100`}>
                        <p className={`${styles.footerCopyright} m-0 text-center w-100`}>
                            &copy; 2024 Daniel Garofoli. All rights reserved.
                        </p>
                        <img src={DGLogo} width={115} className={`${styles.footerLogo} d-none d-md-flex`} alt="Managd" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
